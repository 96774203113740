//variable
@import "partials/variables";
@import "partials/normalize";
@import "partials/grid";
@import "partials/typography";
@import "components/accordion";
@import "components/header";
@import "components/footer";
@import "components/form";




.bg--grey {
    background: $bg-grey;
}

.bg--brown {
    background: $brown;
}

.relative {
    position: relative !important;
}

.z-10 {
    z-index: 10;
}

// .s-all-wrapper {
//     width: calc(100vw - 40px);
// }
.s-all-wrapper {
    width: 100%;
}


.p-card_productSwiper {
    padding-bottom: 100px;
}

.productSwiper {


    .swiper-pagination {
        max-width: 620px;
        width: calc(100vw - 40px);
        transform: translateX(-50%);
        display: flex;
        left: 50% !important;
        bottom: 40px !important;
        height: 4px;
        background-color: $bg-grey;


    }

    .container {
        margin-left: 0;
        padding: 0;
    }

    .swiper-slide {
        height: auto;
        margin-bottom: 20px;
        flex: 0 0 25%;

        max-width: calc(25% - 27px);

        @media(max-width: $grid-breakpoints-xl) {
            flex: 0 0 33.33%;
            max-width: calc(33.33% - 30px);
        }

        @media(max-width: $grid-breakpoints-lg) {
            flex: 0 0 40%;
            max-width: 40%;
        }


        @media(max-width: $grid-breakpoints-sm) {
            flex: 0 0 70%;
            max-width: 70%;
        }


    }

    .swiper-pagination-bullet {
        width: 100%;
        border-radius: 0;
        margin: 0 !important;
        height: 4px;
        background: #3C3C3C;
    }
}

.product__item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: $txt;
    border: 1px solid $line;
    border-bottom: 5px solid $line;

    .p-card__s-info {
        right: 10px;
    }

    &:hover {
        -webkit-box-shadow: 8px 8px 24px -8px rgb(66, 68, 90, 0.28);
        -moz-box-shadow: 8px 8px 24px -8px rgba(66, 68, 90, 0.28);
        box-shadow: 8px 8px 24px -8px rgb(66, 68, 90, 0.28);
    }

    // &:after {
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     display: block;
    //     content: "";
    //     border-bottom: 3px solid $main;
    //     transition: all 0.3s ease-in-out;
    //     width: 0;
    // }

    h3 {
        font-weight: 700;
        text-decoration-color: $main;
        font-style: normal;
        transition: all 0.3s ease-in-out;
        font-size: 16px;
        text-align: left;
        padding: 0 20px;
        display: flex;
        align-items: center;
        height: 100%;
        margin-bottom: 10px !important;
    }
}

.product__item--des {
    h3 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;

    }

    .product__bottom {
        border-top: 3px solid $main;
        padding-top: 10px;
        margin-top: auto;
    }
}

.product__figure {
    padding-top: 100%;
    height: 0;
    width: 100%;
    background: #fff;
    position: relative;
    margin-bottom: 10px;
    border-bottom: 1px solid $line;

    img {
        object-fit: contain;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.product__size {
    padding: 10px 15px;
    border-top: 1px solid $line;
    font-size: 11px;
    color: $txt-light;
    width: 100%;
    transition: all 0.3s ease-in-out;
    margin-top: auto;

    span {
        margin-right: 10px;
    }

    strong {
        font-size: 15px;
        font-weight: 700;
        position: relative;
        height: 26px;
        line-height: 26px;
        margin-left: 8px;
        margin-right: 8px;
        display: inline-block;
        text-align: center;
        min-width: 26px;

        &:before {
            content: "";
            display: block;
            width: calc(100% + 12px);
            height: 26px;
            background: $bg-grey;
            z-index: -1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: skewX(-29deg) translateX(-50%);
        }
    }
}

.product__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    // border-bottom: 3px solid $bg-grey;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;

    .btn {
        margin-right: 15px;
        padding-left: 15px;
        padding-right: 12px;
        margin-left: 0px;
        white-space: nowrap;
    }
}

.product__price {
    font-size: 11px;
    white-space: nowrap;
    color: $txt-light;
    width: 100%;
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: $bg-grey;
        position: absolute;
        transform: skewX(-29deg);
        left: 0;
        top: 0;
        z-index: -1;
    }

    strong {
        color: #000;
        font-size: 19px;
        margin-left: 5px;
    }
}

@media(max-width: $grid-breakpoints-md) {
    .product__bottom {
        display: block;

        .product__price,
        .btn {
            width: calc(100% - 25px);
            margin-left: 10px;
        }

        strong {
            font-size: 18px;
            margin-left: 5px;
        }
    }
}

@media(max-width: $grid-breakpoints-sm) {
    .product__item h3 {
        font-size: 14px;
        text-transform: initial;
    }

}



.is--desktop {
    .product__item:hover {
        // box-shadow: 4px 4px 5px 0px rgba(0, 100, 197, 0.2);
        color: $txt;

        // &:after {
        //     width: 100%
        // }
        h3 {
            color: $main;
        }

        // .product__bottom {
        //     border-color: transparent;
        // }

        .btn {
            &:before {
                background: $red-dark;
                transform: skewX(-29deg);
            }
        }
    }
}




//newsletter--start
.newsletter {
    position: relative;
    padding-top: 45px;
    padding-bottom: 65px;
    background: #333;

    h2 {
        color: #fff;
    }

    p {
        color: rgba(255, 255, 255, 0.48);
    }
}

.newsletter__form {
    display: flex;
    width: 350px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    input {
        font-size: 110%;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding-left: 15px;
    }

    .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

}

.newsletter__img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.6;
    z-index: 1;
}

//newsletter--end




@media(max-width:$grid-breakpoints-md) {
    .footer h3 {
        margin-bottom: 10px;
    }

    .creators {
        margin-right: auto;
    }
}


.section--blue {
    background: $main;
    color: #fff;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        width: 80%;
        height: 100%;
        position: absolute;
        background: #035eb7;
        left: 45%;
        top: 0;
        transform: skewX(-28deg);
        z-index: 5;
    }
}

.card-2 {
    text-align: center;
    margin-bottom: 20px !important;

    figure {
        overflow: hidden;
        height: 0;
        padding-top: 58%;
        width: 100%;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    h3 {
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 20px;
    }

}

@media(max-width:$grid-breakpoints-sm) {
    .card-2 {
        h3 {
            margin-top: 20px;
            margin-bottom: 15px;
            font-size: 18px;
        }
    }
}

#productSwiper {
    &:after {
        display: block;
        content: "";
        width: 12.5%;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 30;
    }
}

.map-right {
    width: 100%;
    position: relative;


    .form-medyczne {
        position: absolute;
        right: 60px;
        top: 10px;
        z-index: 10;
        display: flex;
        justify-content: space-between;
    }

    .btn {
        background: $main;
        margin-left: 5px;

        &:before {
            content: none;
        }
    }

    .gmnoprint[role='menubar'] {
        top: initial !important;

        bottom: 10px !important;
    }

}

.section--blue {
    .h-title {
        margin-top: 10px;
        margin-bottom: 45px;
    }
}

#medyczne-input {
    border: 2px solid $line;
    top: 10px;
    padding: 6px 9px;
    width: calc(100% - 460px);
    top: 10px !important;
    left: 10px !important;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0 !important;
    min-width: 50px;

    &::placeholder {
        color: #000 !important;
        font-weight: 400;
    }
}

@media(max-width:$grid-breakpoints-xl) {

    // #medyczne-input{
    //     top: 55px !important;
    //     left:9px !important
    // }
}

@media(max-width:$grid-breakpoints-md) {

    #map {
        height: 550px !important;
    }

    #medyczne-input {
        width: 100%;
        top: 0 !important;
        left: 0 !important;
        height: 49px;
    }




    .map-right {
        margin-top: 20px;
        padding: 0;

        .gm-control-active.gm-fullscreen-control {
            top: 80px !important;
        }

        .gmnoprint[role='menubar'] {
            top: 80px !important;
        }

        .form-medyczne {
            width: 100%;
            top: 41px;
            left: 0;
            right: initial;

            .btn {
                width: 100%;
                min-width: 50px;
                padding: 0 5px;
                margin: 0;
                white-space: nowrap;

                height: 49px;

                &:first-child {
                    border-right: 1px solid #fff;
                }


            }
        }
    }

}

.map-marker-info {
    color: #000 !important;
    line-height: 1.2;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;

    font-size: 14px;
    width: 100%;
    height: 100%;

    span {
        display: block;
    }

    .map__name {
        font-weight: 700;
        margin-bottom: 5px;
    }

    .map__details {
        color: $main;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 10px;
        font-size: 12px;
    }

    figure {
        width: 100px;
        height: calc(100% + 13px);
        position: absolute;
        top: 0px;
        left: 0;
        object-fit: cover;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


.gm-style-iw+button {
    display: none !important;
}

button.gm-ui-hover-effect {
    display: none !important;
}

/* hide close button in info-window */
::ng-deep button.gm-ui-hover-effect {
    visibility: hidden;
}

.storemapper-iw-container {
    padding: 0 !important;

}

.gm-style-iw-container {
    padding: 0 !important;
    border: 2px solid $main;

}

.gm-style-iw {
    padding: 0 !important;
    border: 2px solid $main;

}




.locations__info {
    padding: 12px 0 0 112px;
    ;
}


.gk__box {

    text-align: center;
    font-weight: 700;
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $main;
    border-bottom: 1px solid $main;
    margin-bottom: 20px;

    color: #000;

    a {
        font-size: 18px;
        line-height: 1.6;
        margin-top: 10px;

        color: #000;

        padding: 10px 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        transition: all .3s ease-in-out;
        cursor: pointer;
        position: relative;
        width: object-fit;
        max-width: calc(100% - 40px);
        background: 0 0;
        z-index: 2;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        color: $main;
        width: fit-content;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: $blue-light;
            position: absolute;
            transform: skewX(-29deg);
            left: 0;
            top: 0;
            transition: all .3s ease-in-out;
            z-index: -1;

        }

        @media(hover:hover) {
            &:hover {
                color: #fff;

                &:before {
                    background: $main;
                }
            }
        }


    }
}




#lb-results .lb-results {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: 0;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    gap: 20px;



    article {
        flex: 0 0 50%;
        max-width: calc(50% - 10px);
        display: block;
        padding-left: 0;
        padding-right: 0px;
        border: none;
        margin-bottom: 30px;
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid $line !important;
        transition: all .3s ease-in-out;

        h2,
        h3 {
            transition: all .3s ease-in-out;
            font-size: 16px;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding: 0 15px;
            display: flex;
            align-items: center;
            height: 100%;

            a {
                color: #000;
            }
        }

        .col-lg-9,
        .col-lg-3 {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }

        .col-lg-3 {
            padding-top: 0;
        }

        .col-lg-9 {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding-bottom: 0;
            margin-bottom: 0;
            padding-top: 0;

            span span {
                display: none;
            }

            >span {
                display: block;
                width: 100%;
                order: -1
            }
        }

        figure {
            order: -1;
            padding-top: 90%;
            height: 0 !important;
            width: 100% !important;
            flex: 0 0 100%;
            position: relative;
            margin-bottom: 10px;
            border-bottom: 1px solid $line;

            img {
                object-fit: contain;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        .col-lg-9 {
            padding-bottom: 10px;
            border-bottom: 1px solid $line;
        }

        article .col-lg-3>div {
            margin-bottom: 10px;
        }

        .col-lg-3 {
            background: transparent;
            padding-top: 10px !important;
            padding-bottom: 0;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 0;

            .message {
                display: none;
            }

            >p {
                display: none;
            }

            label {
                display: none;
            }

            .btn-basket {
                display: flex;
                height: 40px;
                width: calc(100% - 30px);
                text-align: center;

                img {
                    display: none;
                }
            }

            >span {
                font-size: 11px;
                white-space: nowrap;
                color: #858585;
                position: relative;
                height: 40px;
                justify-content: center;
                margin-left: 15px;
                width: calc(100% - 30px);
                display: flex;
                gap: 5px;
                align-items: center;
                margin-bottom: 0px;

                >span {
                    display: none;
                }

                strong {
                    color: #000;
                    font-size: 20px;

                    @media (max-width: 768px) {
                        font-size: 16px;
                    }
                }

                &::before {
                    width: 100%;
                    background: #f3f3f3;
                    transform: skewX(-29deg);
                    left: 0;
                    top: 0;
                    z-index: -1;
                    display: block;
                    content: "";
                    height: 100%;
                    position: absolute;
                }
            }
        }



        @media(hover:hover) {
            &:hover {
                box-shadow: 8px 8px 24px -8px rgba(66, 68, 90, 0.28);

                a h2 {
                    color: $main !important;
                }
            }
        }
    }

    @media (min-width: 1240px) {
        gap: 30px;

        article {
            flex: 0 0 33.33%;
            max-width: calc(33.33% - 20px);
        }
    }


    @media(max-width:$grid-breakpoints-sm) {
        gap: 6px;

        article {
            max-width: calc(50% - 3px);
        }

        article h2 {
            font-size: 14px;
        }

        article .col-lg-3 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

}




.fancybox-is-open .fancybox-bg {
    opacity: 0.85;
}


.gallery {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.gallery__item {
    flex: 0 0 50%;
    max-width: calc(50% - 2px);
    list-style: none !important;
    padding-left: 0 !important;

    a {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 68%;
        position: relative;
        transition: all 0.3s ease-in-out;
        border: 1px solid $line;

        @media(hover:hover) {
            &:hover {
                opacity: 0.9;
                border-color: $main;
            }
        }
    }


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.table__size {
    margin-bottom: 30px;

    min-width: 200px;

    td {
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 700;

        .product__price {

            font-size: 19px;
            color: #000;
        }
    }

    .td--price {
        padding-left: 10px;
        padding-right: 10px;
    }



}

.code-nfz {
    padding: 15px 20px;
}

.gmnoprint {
    z-index: -1 !important;
}

.st__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;
    height: 36px;

    .btn {
        height: 36px;
    }

}

#CookiebotFooter .CookiebotButtonBackground {
    background-color: #15884F !important;
}

#Cookiebot .CookiebotButtonSliderWrapper input:checked+.slider {
    background-color: #15884F !important;
}



.index-p {
    padding-top: 160px;
}


.box-3-wr {
    display: flex;
    gap: 30px;

    padding-bottom: 60px;

    >a {
        flex: 0 0 33.33%;
        max-width: calc(33.33% - 20px);
       
    }

    @media(max-width:992px) {
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;

        >a {
            flex: 0 0 50%;
            max-width: calc(50%);
        }
    }

    @media(max-width:550px) {     

        >a {
            flex: 0 0 100%;
            max-width: calc(100%);
        }
    }
}

.right-box-s {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    border:2px solid transparent;

    img {
        width: 50px;

    }

    >div {
        padding: 10px 10px;
    
        margin-left: 10px;

    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        text-align: left;
        margin: 0;
        margin-bottom:5px;
        padding: 0;
        position: relative;
    }

    p {
        padding: 0;
        margin: 0;
        font-size: 14px;
    }

    &:hover {
       border-color:#23b166;
    }

}


.container--map {
    max-width: 1000px;
}


.violet-box {
    background: $violet-light;
    color: $violet;

    p {
        color: $violet;
    }
}

.blue-box {
    background: $blue-light;
    color: #104b79;

    p {
        color: #104b79;
    }
}

.green-box {
    background: $green-light;
    color: $green;

    p {
        color: $green;
    }
}