//footer

.footer {
    background: #000;
    color: #fff;
    font-size: 14px;
    padding-top: 30px;

    a,
    p,
    ul {
        color: #dedede;
    }

    ul {
        list-style: none;

        a {
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;

            @media(max-width:$grid-breakpoints-md) {
                padding-top: 11px;
                padding-bottom: 11px;
                line-height: 2;
            }
        }
    }

    p>a {
        &:before {
            content: none !important;
        }
    }

    .lh-2 {
        @media(max-width:$grid-breakpoints-md) {
            a {
                padding-top: 11px;
                padding-bottom: 11px;
                display: inline-block;
            }
        }
    }

    h3 {
        text-align: left;
        font-weight: 500;
        margin-bottom: 20px;
    }

    hr {
        border-color: $txt-light2;
        border-bottom: none;
        margin-bottom: 0;
        margin-top: 15px;
    }
}

.footer__logo {
    img {
        max-width: 130px;
    }
}

.footer__social {
    display: flex;
    gap: 20px;

    .btn--border {
        &:before {
            background: transparent;
            border-color: #fff;
            transition: background 0.3s ease-out;
        }

        @media(hover:hover) {
            &:hover {
                &:before {
                    background: $main;
                    border-color: $main;
                }
            }
        }
    }
}

.footer__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-wrap: wrap;

    >* {
        margin-top: 5px;
        margin-bottom: 5px;
    }


    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin-left: 50px;
        margin-right: 50px;
        justify-content: center;

        a {
            color: #fff;
            display: block;
            padding: 5px 10px;
            white-space: nowrap;
        }
    }

    .creators {
        margin-left: auto;
        display: flex;
        gap:5px;
        align-items: center;
        line-height: 1;

        a {
            margin: 0 4px;
            line-height: 1;
            line-height:49px;        }


    }

    .cr-iv{
        margin-top:-4px;
    }
}

@media(max-width:$grid-breakpoints-md) {
    .footer h3 {
        margin-bottom: 10px;
    }
}


.is--desktop {
    .footer a:hover {
        color: #fff;
        text-decoration: underline;
    }
}