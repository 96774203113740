.header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100px;
    position: relative;
    z-index: 90;

    .btn--small {
        padding: 13px 25px;
    }

}

.header {
    top: -70px;
    z-index: 90;
    transition: top 0.3s ease-in-out;

    &.fixed-menu {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100vw;
        box-shadow: 4px 4px 5px 0px rgba(0, 100, 197, 0.1);



        @media(min-width:$grid-breakpoints-xl) {
            .header__logo:before {
                border-top-width: 85px;
            }

            .maintabs,
            .nav__level-3 {
                top: 70px;
            }

            .header__wrapper {
                padding-bottom: 0;
                padding-top: 0;
                height: 70px;
            }

            .maintabs--3>ul {
                top: 77px;
            }

            .maintabs--2>ul {
                top: 62px;
            }
        }

        background:#fff;

        @media(max-width:$grid-breakpoints-sm) {
            height: 40px;

            .nav__level-1 {
                top: 90px;
            }



            .header__logo{
                top:-5px;
                height:30px;
                &:before{
                    top:-30px;
                }

                img{
                    transform:scale(0.8);
                }
            }

            .btn--small.btn--blue2 {
                top:-40px;
                color:$main;
                &:before {
                    content: none;
                }
            }

            .header__right{
                top:40px;
            }

            form{
                top:40px;
            }
            .navbar-toggler{
                top:40px;
            }

        }
    }

    .btn--fb {
        position: relative;
        left: 7px;
    }

    form {
        width: 240px;
        display: flex;
        position: relative;
        z-index: 2;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0;

        .form-search-btn {
            height: 40px;
            min-width: 40px;
            flex: 0 0 40px;
            padding: 0;
            display: block;
            background-image: url("../img/search-2.svg");
            background-size: 17px;
            background-position: center;
            background-color: transparent;
            background-repeat: no-repeat
        }


        input {
            border: none;
            background: transparent;
            width: calc(100% - 40px);
            line-height: 1.3;
            padding-left: 5px;
            padding-right: 0;
            margin-top: 0px;

            &:focus {
                border: none;
                outline: none;

            }

        }

        ::placeholder {
            color: #000;
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: #fff;
            position: absolute;
            transform: skewX(-29deg);
            border: 1px solid $main;
            left: 0;
            top: 0;
            transition: all .3s ease-in-out;
            z-index: -1;
        }

    }
}

.header-login {
    cursor: pointer;
}

.header-login__wrapper {
    display: none;
    width: auto;
    right: 0;
    min-width: 180px;
    position: absolute;
    background: #fff;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.25);
    top: 100%;
    z-index: 100;
    padding: 10px;

    a {
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
        padding: 10px;
        display: block;
        white-space: nowrap;

        &:last-of-type {
            border: none;
        }
    }
}


.nav ul {
    list-style: none;

    a {
        line-height: 1;
        display: block;
    }

    i {
        font-size: 16px;
        padding: 0 6px 0 0;
    }
}

.header__logo {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        width: 900px;
        height: 0;
        border-top: 100px solid $main;
        border-right: 55px solid transparent;
        position: absolute;
        right: -80px;
        top: -15px;

        z-index: -1;
    }

    img {
        width: auto;
    }

    @media(min-width:1400px) {
        margin-left: -60px
    }
}

.circle-number {
    position: absolute;
    right: -12px;
    color: #fff;
    display: block;
    text-align: center;
    font-size: 12px;
    top: -5px;
    padding: 3px 0;
    width: 20px;
    line-height: 15px;
    z-index: 10;
    height: 20px;
    border-radius: 50%;
    background-color: #d9000d;
}

.cart-items {
    display: none;
    width: 350px;
    position: absolute;
    background: #fff;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.25);
    top: 100%;
    right: 0;
    z-index: 100;
}


.btn--border {
    position: relative;
    width: 48px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-left: 7px;

    &:before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 1px solid $main;
        top: 0;
        left: 0;
        z-index: 2;
        transform: skewX(-29deg);
    }

    img {
        position: relative;
        z-index: 5;
    }
}



.header__right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 40;
    margin-left: auto;

    .btn {
        white-space: nowrap;

        &:hover {

            &:after,
            &:before {
                transform: skew(-29deg);
            }
        }

        img {
            display: none;
        }
    }
}

.header-social {
    display: inline-block;
    padding: 5px;

    &:hover {
        opacity: 0.6;
    }
}


.nav__btn {
    background: transparent;
    padding: 10px;
    height: 35px;
    width: 20px;
    margin-left: -15px;
    position: relative;

    &:after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        position: absolute;
        top: 15px;
        transform: rotate(-45deg);

    }
}


@media(min-width:$grid-breakpoints-xl) {


    .nav {
        padding-left: 80px;
        padding-right: 20px;
        position: relative;
        z-index: 90;
    }

    .nav__level-1 {
        display: flex;
        height: 70px;
        margin-top: 15px;
        padding-bottom: 15px;

        a {
            text-transform: uppercase;
            font-style: italic;
            font-size: 14px;
            font-weight: 700;


        }

        >li>a {
            color: $txt;
            display: inline-block;
            padding: 10px 15px;
            white-space: nowrap;

            &:hover {
                color: $main;
            }
        }

        >li {
            display: flex;
            align-items: center;
            // position: relative;

            &:not(.nav__li--toggle) {
                .nav__btn {
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    opacity: 0;
                }
            }
        }

        .nav__li--toggle {
            position: relative;

            .nav__btn {
                border-top: 2px solid $txt;
                border-bottom: 2px solid $txt;
                height: 10px;
                width: 11px;
                padding: 0;
                position: absolute;
                left: 15px;

                &:after {
                    height: 0;
                    border-left: none;
                    border-color: $txt;
                    transform: none;
                    top: 2px;
                    width: 100%;
                }
            }
        }
    }

    .nav__level-1>li>a:hover+.nav__btn {
        border-color: $main;

        &:after {
            border-color: $main;
        }
    }


    .maintabs--2 {
        position: relative;

        >ul {
            list-style: none;
            padding: 20px;
            min-width: 300px;
            top: 77px;
            left: 0px;
            display: none;
            position: absolute;
            background: $bg-grey;
            z-index: 10;
            box-shadow: 0px 28px 33px -14px rgba(0, 0, 3, 0.3);

            padding-bottom: 20px;

            &.open {
                display: block;
            }

            a {
                color: #000;
                padding: 10px 0;
                width: 100%;
                display: block;
                font-weight: 400;
                font-size: 14px;
                white-space: nowrap;

                @media(hover:hover) {
                    &:hover {
                        color: $main;
                    }
                }

            }
        }
    }

    .maintabs--3 {
        >ul {
            list-style: none;
            padding: 20px;
            justify-content: flex-start;
            width: 1120px;
            top: 92px;
            left: 26px;
            display: none;
            position: absolute;
            flex-wrap: wrap;
            background: $bg-grey;
            z-index: 10;
            box-shadow: 0px 28px 33px -14px rgba(0, 0, 3, 0.3);

            padding-bottom: 20px;
            -webkit-column-count: 4;
            -moz-column-count: 4;
            column-count: 4;

            @media(max-width: 1400px) {
                width: calc(100vw - 280px);
                -webkit-column-count: 43;
                -moz-column-count: 3;
                column-count: 3;
            }

            &.open {
                display: block;
            }

            >li>a {
                padding-bottom: 0 !important;
            }

            li {
                display: block;
                width: 100%;
                position: relative;
                break-inside: avoid-column;


                a {
                    font-size: 14px;
                    color: #000;
                    padding: 10px 0;
                    display: inline-block;
                    position: relative;
                    width: auto;
                    text-align: left;

                }

                ul {
                    padding-left: 10px;
                    list-style-type: none;
                    margin-bottom: 0;

                    a {
                        color: #000;
                        padding: 7px 0px;
                        width: 100%;
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        text-transform: initial;
                    }
                }
            }


        }

    }


    .maintabs {
        position: fixed;
        background: #005cb4;
        width: 43%;
        top: 85px;
        left: 0;
        padding-top: 34px;
        height: 370px;
        display: none;

        &.open {
            display: block;
        }

        >li {
            width: 300px;
            margin-right: 0;
            margin-left: auto;
            display: flex;


            >a {
                color: #fff;
                display: block;
                width: 100%;
                padding: 11px 10px 11px 0;
                font-size: 14px;
                font-weight: 700;
                position: relative;
                font-style: italic;


                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    border-left: 16px solid #005cb4;
                    display: inline-block;
                    z-index: 30;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s ease-in-out;
                }

                &:hover,
                &.active {
                    color: $blue2;

                    &:after {
                        right: -15px;
                    }

                }
            }

            >.nav__btn {
                display: none;
            }
        }
    }

    .nav__level-3 {
        padding-top: 30px;
        padding-left: 50px;
        position: fixed;
        background: $bg-grey;
        width: 57%;
        top: 85px;
        right: 0;
        height: 370px;
        display: none;

        &.open {
            display: block;
        }


        a {
            color: $txt;
            font-size: 14px;
            padding: 7px 10px 7px 0;


            &:hover {
                color: $main;
            }
        }
    }

    .nav__level-3-ul {
        width: 380px;
        height: 290px;
        overflow-y: auto;

    }

    .nav__level-3 .wrapper__img--square,
    .nav__img {
        width: calc(100% - 470px);
        max-width: 330px;
        position: absolute;
        left: 440px;
        top: 30px;

        img {
            width: 100%;
            height: 310px;
            object-fit: cover;

        }
    }

    .nav__title {
        width: 380px;
        font-size: 28px;
        display: block;
        line-height: 1.1;
        font-weight: 500;
        font-style: italic;
        text-transform: uppercase;
        color: $main;
        margin-bottom: 15px;
        grid-area: gridTitle;
    }

    .nav__mark {
        display: none;
    }

    .nav-overlay {
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        z-index: 2;
    }

    .navbar-toggler {
        display: none;
    }
}

// @media(max-width:$grid-breakpoints-sm) {
//     .header__right {
//         .btn {
//             padding: 10px 9px;

//             span {
//                 display: none
//             }

//             img {
//                 margin-right: 0;
//             }
//         }
//     }
// }

@media(max-width:$grid-breakpoints-xl) {

    .header__wrapper.container {
        max-width: 100% !important;
    }

    .nav {}

    .nav-overlay {
        display: none;
    }

    .header__logo {
        top: 5px;

        &:before {
            top: -50px;
        }

        img {
            margin-top: -7px;
        }
    }

    .header__right {
        padding-right: 60px;
    }

    .header__wrapper {
        padding-right: 70px;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 66px;
        background: #fff;
    }

    .header__right {
        padding-right: 0;
    }

    .navbar-toggler {
        display: block;
        background: transparent;
        position: absolute;
        top: 5px;
        right: 10px;
        width: 50px;
        height: 50px;
        margin-right: -5px;
        z-index: 50;

        span {
            position: absolute;
            width: 30px;
            height: 3px;
            border-radius: 2px;
            display: block;
            background: $main;
            left: 5px;
            transition: all 0.3s ease-in-out;

            &:first-child {
                top: 23px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                top: 23px;
                display: none;
            }

            &:nth-child(3) {
                top: 23px;
                transform: rotate(-45deg);
            }
        }

        &.collapsed {

            span {

                &:first-child {
                    top: 13px;
                    transform: rotate(0);
                }

                &:nth-child(2) {
                    top: 23px;
                    display: block;
                }

                &:nth-child(3) {
                    top: 33px;
                    transform: rotate(0);
                }
            }

        }
    }

    .nav__level-1,
    .nav__level-2,
    .nav__level-3,
    .nav__mark {
        display: none;
    }

    .nav__level-1 {
        position: fixed;
        top: 60px;
        background: $main;
        z-index: 90;
        left: 0px;
        width: 100vw;
        padding: 0;
        height: calc(100vh - 60px);
        overflow: auto;
        padding-bottom: 60px;

        .nav__btn {
            &:after {
                border-color: #fff;
            }
        }

        a {
            color: #fff;
            padding-right: 50px;
            padding: 15px 20px;
            font-weight: 500;
            ;
            font-size: 16px;
        }

        >li {
            border-bottom: 1px solid #fff;

            >a {
                text-transform: uppercase;
            }
        }

        li {
            position: relative;


            ul {}

        }

        .nav__btn {
            position: absolute;
            right: 0px;
            top: 0;
            width: 60px;
            height: 45px;

            &:after {
                width: 10px;
                height: 10px;
                margin-left: 20px;
            }
        }
    }

    .maintabs {
        background: #DCEAF7;

        a {
            color: #000;
        }

        .nav__btn:after {
            border-color: #000;
        }
    }

    .maintabs,
    .maintabs--2,
    .maintabs--3 {

        max-height: 300px;
        overflow: auto;

        ul {
            background: #DCEAF7;

            a {
                color: #000;
                padding: 12px 12px 12px 40px;
            }
        }

        >li>a {
            text-transform: uppercase;

        }


    }

    // .nav__level-3 {


    //     a {
    //         font-weight: 400;
    //         padding: 12px 15px;
    //     }
    // }

    .nav__level-3 .wrapper__img--square,
    .nav__img {
        display: none;
    }

    .nav__title {
        display: none;
    }

    .nav__mark {
        background: $bg-grey;
        max-width: 100% !important;
        text-align: center;

        li {
            display: inline-block;
        }
    }

    .header form {
        margin-left: 70px;
    }


}


@media(max-width:$grid-breakpoints-md) {

    // .header__right .btn.btn--blue2 {
    //     display: none;
    // }

    .header__logo:before {
        top: -50px;
    }

    .header form {
        width: calc(100% - 60px);
        margin-left: 0;
        position: absolute;
        top: 66px;
        left: 50%;
        transform: translateX(-50%);
    }

    .header {
        margin-bottom: 54px;
    }

    .header.fixed-menu {
        // form {
        //     display: none;
        // }

        // .header__right{
        //    width:0;
        //    height: 0;
        //    .btn--border{
        //     display:none;
        //    }
        // }

        // .navbar-toggler{
        //     display:none;
        // }


    }
}

@media(max-width:$grid-breakpoints-sm) {
    .header__logo {

        img {
            height: 35px;
        }

        &:before {
            border-top-width: 60px;
            border-right-width: 35px;
            right: -40px;
            top: -10px;
        }
    }

    .header form {
        border-left: 1px solid $line;
        border-right: 1px solid $line;
        width: calc(100% - 190px);
        left: 62px;
        transform: none;
        height: 50px;
        z-index: 50;
        padding-right: 0;
        padding-left: 5px;

        .form-search-btn {
            height: 50px;
            flex: 0 0 50px;
            min-width: 50px;
        }

        &:before {
            content: none;
        }



    }




    .navbar-toggler {

        position: absolute;
        top: 5px;
        right: initial;
        left: 10px;
        top: 66px;


    }

    .header-social {
        img {
            width: 30px;
        }
    }

    .btn--small.btn--blue2 {
        position: absolute;
        right: 25px;
        top: -55px;

        span {
            display: block;
        }
    }


    .nav__level-1 {
        top: 115px;
    }

    .header__right {
        background: #fff;
        margin-left: 30px;
        padding-right: 0;
        position: absolute;
        top: 66px;
        right: 0px;
        width: 100%;
        border-top: 1px solid $line;
        border-bottom: 1px solid $line;
        justify-content: flex-end;
        height: 50px;

        .btn--border:before {
            content: none;
        }

        .circle-number {
            right: 4px;
            top: 0;

        }

        .btn--border {
            height: 50px;
            width: 60px;
        }

        .header-login {
            border-right: 1px solid $line;
        }

    }
}

@media(max-width:400px) {
    .header__logo {
        img {
            height: 30px;
        }

        &:before {
            border-top-width: 60px;
            border-right-width: 35px;
            right: -35px;
            top: -10px;
        }

    }
}

.is--desktop {
    .btn--border:hover {
        &:before {
            background: $blue-light;
            border-color: $blue-light;
        }
    }
}


.menu-is-open {
    .header {
        background: #fff;
    }
}