.accordion__content {
    display: none;
    padding: 0;
    transition: all 0.3s ease-out;
}

.js-accordion__panel {
    border: 1px solid $line;
    padding: 5px 20px;
}


.accordion__trigger {
    border: none;
    width: 100%;
    display: block;
    position: relative;
    text-align: left;
    background: transparent;
    font-size: 16px;
    // background: $main;
    font-weight: 700;
    padding: 15px 15px 15px 0px;
    color: $txt;
    // color: #fff;


    &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-left: 2px solid $main;
        border-bottom: 2px solid $main;
        position: absolute;
        top: 19px;
        right: 0;
        transform: rotate(-45deg);
        transition: all 0.3s ease-out;
        transform-origin: center center;
    }

}

.accordion__trigger[aria-expanded="true"] {
    &:after {
        transform: rotate(135deg);
        top: 21px;
    }
}