/* montserrat-300 - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;   
    src: url('../fonts/montserrat-v25-latin-ext_latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/montserrat-v25-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v25-latin-ext_latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v25-latin-ext_latin-300.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-regular - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;   
    src: url('../fonts/montserrat-v25-latin-ext_latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/montserrat-v25-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v25-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v25-latin-ext_latin-regular.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-600 - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;   

    src: url('../fonts/montserrat-v25-latin-ext_latin-600.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/montserrat-v25-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v25-latin-ext_latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-600.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v25-latin-ext_latin-600.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-700 - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/montserrat-v25-latin-ext_latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/montserrat-v25-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v25-latin-ext_latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v25-latin-ext_latin-700.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-800 - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/montserrat-v25-latin-ext_latin-800.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/montserrat-v25-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v25-latin-ext_latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-800.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v25-latin-ext_latin-800.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v25-latin-ext_latin-800.svg#Montserrat') format('svg');
    /* Legacy iOS */
}


/* roboto-300 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v30-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

html{
    scroll-behavior: smooth;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    z-index: -100;
}

body {

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    font-size:16px;
    font-family: 'Montserrat',
    sans-serif;
    color: $txt;
    line-height:1.4;
}


input,
textarea,
button,
::placeholder,
:after,
:before {
    font-family: 'Montserrat', sans-serif;
}

.underline {
    text-decoration: underline !important;
}

.nowrap {
    white-space: nowrap;
}

.lh-2 {
    line-height: 2;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-medium {
    font-weight: 600 !important;
}

.font-weight-thin {
    font-weight: 300 !important;
}

.color-main {
    color: $main !important;
}

.color-white {
    color: #fff;
}

.message {
    margin: 5px 0;
    padding: 12px 10px;
}

.message p {
    padding: 0;
    font-size: 14px;
}

.green {
    color: $green;
}

.red {
    color: $red !important;
}

.main {
    color: $main !important;
}

.main-dark {
    color: $blue-dark !important;
}

.violet {
    color: $violet !important;
}

.color-light {
    color: $txt-light !important;
}

.bg--greyLight {
    background: #fafafa;
}

.bg--light {
    background: rgba(0, 114, 188, 0.2);
}

.bg--light2 {
    background: rgba(0, 114, 188, 0.1);
}

.info {
    display: block;
    width: 100%;
    max-width: 600px;
    background: $bg-grey;
    margin: 20px auto;
    text-align: center;
    padding: 10px 20px;
    font-weight: 700;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.description {
    color: $txt-light;
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
    color: $link;
    transition: all 0.3s ease-in-out;

}

.is--desktop {
    a:hover {
        color: $link-hover;
    }
}

a,
button {
    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 2px dotted $blue;
        outline-offset: 3px;
    }
}


h1 {
    font-size: 32px;
    margin-bottom: 30px;
}

h2 {
    font-size: 28px;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 900;
}

h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    margin-top: 0;
}


.text-page {

    p,
    ul,
    ol {
        margin-bottom: 20px;
        font-size: 16px;
    }

    ul,
    ol {
        padding-left: 30px;
    }

    li {
        margin-bottom: 10px;
    }

    h2 {
        margin-top: 30px;
    }

    h3 {
        margin-top: 20px;
    }

    img,
    iframe {
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    a {
        text-decoration: underline
    }
}

.link {
    text-decoration: underline;
}

.h-title {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    font-size: 34px;
    margin-bottom: 35px;
}

.h-3 {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-size: 21px;
}

.h-2 {
    font-size: 24px;
}

.btn,
.btn-basket,
.btn-blue,
.btn--blue2,
.btn-blue--1 {
    font-size: 18px;
    line-height: 1;

    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    padding: 0px 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    height: 54px;
    margin-left: 20px;
    background: transparent;
    z-index: 2;

    // &:after {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     border-top: 54px solid $red;
    //     border-right: 30px solid transparent;
    //     position: absolute;
    //     left: 100%;
    //     top: 0;
    //     transition: all 0.3s ease-in-out;
    //     z-index: -1;
    // }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: $red;
        position: absolute;
        transform: skewX(-29deg);
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

    &:hover {
        color: #fff !important;

        &:before {
            //transform: rotateX(-180deg) skewX(-29deg);
            background: $red-dark;
        }
    }
}


.btn-blue,
.btn--blue2,
.btn-blue--1 {
    font-size: 14px;
    height: 40px;
    padding: 0 17px;

    &:before {
        background: $blue;
    }

    &:hover {
        color: #fff !important;

        &:before {
            //transform: rotateX(-180deg) skewX(-29deg);
            background: rgb(6, 65, 125);
        }
    }
}


.btn--size {
    text-transform: initial;
    color: #000;
    font-weight: 400;

    &:before {
        background: #ddd;
    }

}



@media(max-width:$grid-breakpoints-xl) {
    h2 {
        font-size: 24px;
    }
}


@media(max-width:$grid-breakpoints-md) {
    .btn {
        padding: 12px 15px;
        font-size: 16px;
        height: 40px;
    }

    .h-title {
        font-size: 28px;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 22px;
    }

}

@media(max-width:$grid-breakpoints-sm) {
    h1 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .h-2 {
        font-size: 20px;
    }

}


.new {
    font-size: 20px;
    color: $main;
    text-transform: uppercase;
    font-weight: 800;
    font-style: italic;
    margin-bottom: 10px;
    display: block;
}


.btn--tab {
    color: #000;
    background: none;

    &:before {
        background: rgb(187, 187, 187, 0.2);
    }

    &:hover,
    &.active,
    &[aria-selected="true"] {

        color: #fff;

        &:before {
            background: $red;
        }
    }
}



.btn--small,
.btn--tab {
    font-size: 14px;
    height: 40px;
    padding: 0 17px;
}

.btn--tab {
    margin-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    height: 50px;
    line-height: 1.2;

    &:hover {
        &:before {
            transform: skewX(-29deg);
        }
    }
}

.img-fluid {
    max-width: 100%;
}



.pagination {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 50px;

    ul {
        list-style: none;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }


    a {
        display: flex !important;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        height: 40px;
        color: $input-border;
        border: 2px solid $input-border;
        transition: all 0.3s ease-in-out;

        @media(hover:hover) {
            &:hover {
                border-color: $blue;
                color: $blue;
            }
        }

        &[title="Poprzednia"],
        &[title="Następna"] {
            border: none;
            max-width: auto;
        }

        &[title="Poprzednia"] {
            margin-right: 5px;
        }

        &[title="Następna"] {
            margin-left: 5px;
        }
    }

    .active a {
        border-color: $blue;
        color: $blue;
        background: rgba(0, 99, 197, 0.1);
    }

    @media(max-width:$grid-breakpoints-sm) {
        margin-top: 30px;
        margin-bottom: 30px;
        gap: 5px;
    }

}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}


.breadcrumbs__wrapper {
    border-bottom: 1px solid $line;
    margin-bottom: 40px;
}

.breadcrumbs {

    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;


    a {
        &:after {
            content: "/";
            padding-left: 5px;
        }

        &:hover {
            color: $main;

            span {
                color: inherit;
            }
        }
    }

    span,
    a {
        color: $txt-light;
        font-size: 14px;
        padding: 3px 5px 3px 0;
        display: inline-block;
        font-style: italic;
        font-weight: 500;
        line-height: 1;

        span {
            padding: 0;
        }
    }

    @media(max-width: $grid-breakpoints-md) {
        margin-bottom: 25px;

        span,
        a {
            font-size: 13px;
        }
    }
}

.subpage-header {
    background-image: linear-gradient(to right, rgba(0, 103, 190, 0.2), rgba(0, 103, 190, 0));
    height: 156px;
    position: relative;
    overflow: hidden;
    padding-bottom: 0 !important;

    h1,
    h2 {
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;


        @media(min-width: $grid-breakpoints-xl) {
            padding-left: 150px;
            padding-right: 50px;
        }

        @media(min-width: 1400px) {
            padding-left: 200px;
            padding-right: 50px;
        }



    }



    &.subpage-header--list {
        h1.h2 {

            span {
                font-size: 22px;
                display: block;
                margin-top: 15px;

            }
        }
    }

    h1 {
        font-size: 40px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
        position: relative;
        z-index: 20;
        font-style: italic;
        font-weight: 700;

    }


    .container {
        position: relative;

        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}

.s-header__left {
    position: absolute;

    bottom: 0;

    @media (min-width:$grid-breakpoints-sm) {
        right: calc(100% - 80px);
    }

    @media(min-width: $grid-breakpoints-xl) {
        left: -780px;
        right: initial;
    }

    @media(min-width:1400px) {
        left: -750px
    }
}

.s-header__right {
    position: absolute;

    bottom: 0;

    @media (min-width:$grid-breakpoints-md) {
        left: calc(100% - 80px);
    }

    @media(min-width: $grid-breakpoints-xl) {
        right: 0;
        left: initial;
    }

}

@media(max-width: $grid-breakpoints-xl) {

    .subpage-header h1 {
        font-size: 36px;
        padding: 0;
    }

    .subpage-header.subpage-header--list {
        h1 {
            span {
                font-size: 20px;


            }
        }
    }


}

@media(max-width: $grid-breakpoints-md) {
    .subpage-header h1 {
        font-size: 30px;
        padding: 0;
    }

    .subpage-header.subpage-header--list {
        h1 {

            span {
                font-size: 18px;
            }
        }
    }


    .s-header__right {
        right: -56px;
    }
}

@media(max-width: $grid-breakpoints-sm) {
    .s-header__left {
        left: -880px;
    }

    .s-header__right {
        right: -100px;
    }

    .subpage-header h1 {
        font-size: 24px;
    }

}


.file {
    ul {
        padding-left: 0;
        list-style: none;
    }

    a {
        width: 100%;
        max-width: 540px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 7px;
        padding-bottom: 7px;
        position: relative;

        strong {
            color: $grey;
            font-weight: 400;
        }
    }
}

.file a:hover {
    strong {
        color: $main;
    }

    &:after {
        width: 100%;
    }
}

//product-end

.p-card__s-info {
    position: absolute;
    top: 10px;
    right: 45px;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    color: $grey;
    font-size: 9px;
    display: block;
    z-index: 10;
    line-height: 1;
    display: flex;
    align-items: center;

    img {
        margin-left: 5px;
    }

}


.rodo-info {
    font-size: 12px;
    margin-top: 30px;

    p {
        font-size: 12px !important;
        margin-bottom: 10px !important;
    }
}


.cooper {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #DCEAF7;
    overflow: hidden;
}


.cooper__img--l {

    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.cooper__img--r {

    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
}


@media(max-width:$container-max-widths-xl) {

    .cooper__img--l {
        top: 50%;
    }

    .cooper__img--r {
        top: -50%;
    }
}

.cooper__item {
    flex: 0 0 25%;
    max-width: 25%;

    font-size: 15px;
    text-align: center;
    margin-bottom: 25px;


    &:not(:last-child) {
        figure {
            border-right: 1px solid $main;
        }
    }

    figure {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        img {
            max-width: 90%;
        }


    }

    h4 {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-weight: 400;
        max-width: 190px;
    }






}


.cooper__list {
    display: flex;
    margin-bottom: 35px;
    margin-top: 30px;
    list-style: none;
    padding-left: 0;

    @media(max-width:$container-max-widths-xl) {

        flex-wrap: wrap;

        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        .cooper__item {
            flex: 0 0 50%;
            max-width: 50%;

            &:not(:nth-child(odd)) {
                figure {
                    border-right: none;
                }
            }
        }
    }

}