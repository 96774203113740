.section--50 {
    padding-top: 50px;
    padding-bottom: 50px;

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.section--40 {
    padding-top: 40px;
    padding-bottom: 40px;

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.section--30 {
    padding-top: 30px;
    padding-bottom: 30px;
}



.container {
    max-width: $container-max-widths-xxl;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    @media(max-width:$grid-breakpoints-xl) {
        max-width: $container-max-widths-lg;
    }

    @media(max-width:$grid-breakpoints-lg) {
        max-width: $container-max-widths-md !important;
    }

    @media(max-width:$grid-breakpoints-md) {
        max-width: $container-max-widths-sm !important;
    }

    @media(max-width:$grid-breakpoints-sm) {
        max-width: 460px !important;
    }
}


.container-p0 {
    margin-right: auto;
    margin-left: auto;

    .row {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}



.container--xl {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.container--lg {
    max-width: $container-max-widths-lg;
    margin-left: auto;
    margin-right: auto;
}

.container--md {
    max-width: $container-max-widths-md;
    margin-left: auto;
    margin-right: auto;
}

.container--sm {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}


.container--mobile-full {
    @media(max-width:$grid-breakpoints-md) {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

}



@mixin row($padding) {
    margin-left: -$padding;
    margin-right: -$padding;
    // margin-bottom: -$padding;

    [class*="col-"] {
        padding-left: $padding;
        padding-right: $padding;
        margin-bottom: $padding;

    }

    @media(max-width:$grid-breakpoints-sm) {
        margin-left: -10px;
        margin-right: -10px;

        [class*="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    @include row(15px);
    list-style: none;
    padding-left: 0;

}

.row--30 {
    @include row(30px !important);
}

.row--20 {
    @include row(20px !important);
}

.row--10 {
    @include row(10px !important);
}

.row--5 {
    @include row(5px !important);
}

.row--0 {
    @include row(0 !important);
}

.overflow-hidden {
    overflow: hidden;
}


.d-block {
    display: block !important;
}

.d-block {
    display: inline-block !important;
}

.d-flex {
    display: flex;
}

.d-flex-md {
    @media(min-width:$grid-breakpoints-md) {
        display: flex;
    }
}

.align-center {
    align-items: center;
}

.flex-wrap-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.flex-all-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.flex-fs-sb {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-center-sb {
    display: flex;
    align-items: cenetr;
    justify-content: space-between;
}

.flex-wrap-l-c {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.flex-wrap-end {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.justify-content-center {
    justify-content: center;
}


.z-10 {
    position: relative;
    z-index: 10;
}

[class*="col-"]{
    width:100%;
}

.col-lg-20 {
    @media(min-width:$grid-breakpoints-lg) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.col-md-20 {
    @media(min-width:$grid-breakpoints-md) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.w-100 {
    width: 100% !important;
}

.col-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
}

.col-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.col-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
}

.col-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
}

.col-11 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}




@media(min-width: $grid-breakpoints-sm) {
    .col-sm-1 {
        flex: 0 0 8.33%;
        max-width: 8.33%;
    }

    .col-sm-2 {
        flex: 0 0 16.66%;
        max-width: 16.66%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .col-sm-5 {
        flex: 0 0 41.66%;
        max-width: 41.66%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33%;
        max-width: 58.33%;
    }

    .col-sm-8 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33%;
        max-width: 83.33%;
    }

    .col-sm-11 {
        flex: 0 0 91.66%;
        max-width: 91.66%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


@media(min-width: $grid-breakpoints-md) {

    .container-md-flex {
        display: flex;

        div {
            width: 100%;
        }

        iframe {
            flex: 0 0 50%;
            max-width: 50%;
            margin-left: 20px;
        }
    }

    .col-md-1 {
        flex: 0 0 8.33%;
        max-width: 8.33%;
    }

    .col-md-2 {
        flex: 0 0 16.66%;
        max-width: 16.66%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .col-md-5 {
        flex: 0 0 41.66%;
        max-width: 41.66%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33%;
        max-width: 58.33%;
    }

    .col-md-8 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33%;
        max-width: 83.33%;
    }

    .col-md-11 {
        flex: 0 0 91.66%;
        max-width: 91.66%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }


}


@media(min-width: $grid-breakpoints-lg) {

    .col-lg-1 {
        flex: 0 0 8.33%;
        max-width: 8.33%;
    }

    .col-lg-2 {
        flex: 0 0 16.66%;
        max-width: 16.66%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .col-lg-5 {
        flex: 0 0 41.66%;
        max-width: 41.66%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33%;
        max-width: 58.33%;
    }

    .col-lg-8 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33%;
        max-width: 83.33%;
    }

    .col-lg-11 {
        flex: 0 0 91.66%;
        max-width: 91.66%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

}

@media(min-width: $grid-breakpoints-xl) {

    .col-xl-1 {
        flex: 0 0 8.33%;
        max-width: 8.33%;
    }

    .col-xl-2 {
        flex: 0 0 16.66%;
        max-width: 16.66%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .col-xl-5 {
        flex: 0 0 41.66%;
        max-width: 41.66%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33%;
        max-width: 58.33%;
    }

    .col-xl-8 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33%;
        max-width: 83.33%;
    }

    .col-xl-11 {
        flex: 0 0 91.66%;
        max-width: 91.66%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-xl-20 {
        flex: 0 0 20%;
        max-width: 200%;
    }
}

.mr-5 {
    margin-right: 5px !important;
}
.mr-2 {
    margin-right: 0.5rem!important;
}

.ml-1 {
    margin-left: 0.25rem!important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-auto {
    margin-left: auto !important;
}



.ml-10 {
    margin-left: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}
.mr-10 {
    margin-right: 10px !important;
}

.mrl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.mrl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mt-0 {
    margin-top: 0px !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-30-md {
    @media(max-width:$grid-breakpoints-md) {
        margin-bottom: 30px !important;
    }
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;

    @media(max-width:$grid-breakpoints-sm) {
        margin-bottom: 50px !important;
    }
}

.mt-50 {
    margin-top: 50px !important;

    @media(max-width:$grid-breakpoints-sm) {
        margin-top: 50px !important;
    }
}


.mt-30-xl {
    @media(min-width:$grid-breakpoints-lg) {
        margin-top: 30px !important;
    }
}

.mt-20-xl {
    @media(min-width:$grid-breakpoints-lg) {
        margin-top: 20px !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}


.pb-30 {
    padding-bottom: 30px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-40 {
    padding-top: 40px !important;

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 30px;
    }
}

.pt-md-50 {
    padding-top: 50px !important;

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 30px !important;
    }
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pb-md-50 {
    padding-bottom: 50px !important;

    @media(max-width:$grid-breakpoints-sm) {
        padding-bottom: 30px !important;
    }
}

.pb-50 {
    padding-bottom: 50px !important;
}

.plr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pr-30-desktop {
    @media(min-width:$grid-breakpoints-lg) {
        padding-right: 30px !important;
    }
}

.pr-50-desktop {
    @media(min-width:$grid-breakpoints-lg) {
        padding-right: 50px !important;
    }
}

.p-l-55 {
    padding-left: 55px !important;
}

.p-l-35 {
    padding-left: 35px !important;
}