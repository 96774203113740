.disabled {
    color: $placeholder;
}

fieldset {
    border: none;
    padding: 0;


}


.form-h-info {
    margin-top: 5px;
    font-size: 10px;
    max-height: 0;
    overflow: hidden;
    color: $txt-light;
    transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
    max-height: 100px;
}


.form__select {

    padding: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 25px;
    z-index: 10;
    width: 100%;

    label {
        color: $label;
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 700;
        display: block;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;


        border: 1px solid $input-border;
        border-radius: 6px;
        padding: 12px 45px 12px 15px;
        display: block;
        background: transparent;
        width: 100%;
        font-size: 18px;
        font-family: 'Roboto', sans-serif;

        line-height: 1.5;
        cursor: pointer;

        &:focus,
        &:focus-visible {
            outline: 2px solid #76b738;
            border-color: #76b738;
            box-shadow: none;
            outline-offset: 0;
            // outline:none;
        }
    }

    option {
        border: none;
        padding: 10px;
        font-size: 16px;
        color: $input;
        line-height: 40px;
    }

    &:after {
        content: "";
        position: absolute;
        right: 15px;
        bottom: 25px;
        z-index: -1;
        width: 8px;
        height: 8px;
        display: block;
        border-left: 2px solid $input;
        border-bottom: 2px solid $input;
        transform: rotate(-45deg);
    }

    @media(max-width: $grid-breakpoints-sm) {
        width: 100%;

        select {
            width: 100%;
        }
    }

}




.form__checkbox {
    margin-bottom: 15px;

    input {
        position: absolute;
        z-index: -1000;
        width: 0px;
        height: 0px;
        overflow: hidden;
        opacity: 0;
    }

    a {
        text-decoration: underline;

    }

    label {
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        color: $label;
        font-size: 14px;
        line-height: 1.4;
        cursor: pointer;
        display: inline-block;

        &:before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0px;
            left: 0;
            display: block;
            border: 1px solid $input-border;
            background-color: #fff;
            transition: all 0.3s ease-out;
            border-radius: 2px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            left: 5px;
            width: 0;
            height: 0px;
            transform: rotate(-45deg);
            transition: all 0.3s ease-out;
        }
    }

    input:checked~label:before {
        background-color: $main;

    }


    input:checked~label:after {

        width: 10px;
        height: 6px;
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
    }

    input:focus-visible~label:before {
        outline: 1px solid $main;
        outline-offset: 1px;
    }

    input[type="radio"]~label {

        &:before {
            border-radius: 50%;
        }
    }

    input[type="radio"]:checked~label:after {
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background: $main;
        top: 4px;
        left: 5px;

    }

    input[type="radio"]:checked~label:before {
        background: #fff;
        outline:1px solid $line;
    }

    &.is--invalid {

        label:before {
            border-color: $red;
        }
    }


}


.form__checkbox--small {
    label {
        font-size: 11px;

        &:before {
            top: 0;
        }

        &:after {
            top: 6px;
        }
    }
}

.form__checkbox--center {
    label {

        &:before {
            top: -2px;
        }

        &:after {
            top: 4px;
        }
    }
}

.form__checkbox--medium {
    label {
        font-size: 16px;
    }
}

.input--data {
    background: url("../img/ico/ico-date-input.svg") right 15px center no-repeat;
}

.w-100{
    width:100% !important;
    flex:  0 0 100% !important;
    max-width:100% !important;
}

.form__input {
    margin-bottom: 20px;
    position: relative;
    font-family: 'Roboto', sans-serif;


    ::placeholder{
        font-family: 'Roboto', sans-serif;
    }

    label {
        font-size: 10px;
        margin-bottom: 6px;
        font-weight: 700;
        color: #76B738;
        display: block;
        position: absolute;
        background: #fff;
        padding: 0 5px;
        top: 10px;
        z-index: -1;
        opacity: 0;
        transition: top 0.3s ease-out;
    }

    textarea {
        width: 100% !important;
    }


    input,
    textarea {
        border-radius: 6px;
        border: 1px solid $input-border;
        width: 100%;
        display: block;
        color: $input;
        font-size: 18px;
        padding: 12px 15px;
        min-height: 54px;

        &:focus,
        &:focus-visible {
            border-color: #76b738;
            box-shadow: none;
            box-shadow: inset 0px 0px 0px 1px #76b738;
            outline:none;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    input:focus ~ label, input:not(:placeholder-shown) ~ label,
    textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label
    input:valid:required ~ label {
        opacity: 1;
        top: -5px;
        left: 20px;
        z-index: 5;
    }


    &.is--invalid {

        label {
            color: $red;
        }

        input,
        textarea {
            border-color: $red !important;
            box-shadow: inset 0px 0px 0px 1px $red;
            background-image: none;

            &:focus,
            &:focus-visible {
                outline: 2px solid $red;
                border-color: $red;
                box-shadow: none;

                // outline:none;
            }
        }

        &:after {
            content: "+";
            transform: rotate(45deg);
            color: $red;
            position: absolute;

            top: 0px;
            right: 20px;
            font-size: 40px;

        }
    }

    &.is--valid {

        input,
        textarea {
            border-color: #76B738 !important;
            box-shadow: inset 0px 0px 0px 1px #76B738;
            background-image: none;


        }

        &:after {
            content: "";
            transform: rotate(-45deg);
            border-left: 2px solid $green;
            border-bottom: 2px solid $green;
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 12px;
            height: 8px;
        }
    }

    @media(max-width: $grid-breakpoints-lg) {
        input,
        textarea {
            font-size: 16px;
        }
    }

}


.form__input--bold {
    label {
        font-size: 18px;
        color: $txt;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &.is--valid:after {
        top: 52px;
    }

    &.is--invalid:after {
        top: 46px;
    }

}

.form__nolabel {
    &.is--valid {
        &:after {
            top: 15px;
        }
    }

    &.is--invalid {
        &:after {
            top: 7px;
        }
    }
}


.form__input--error {
    color: $red;
    font-size: 10px;
    display: block;
    margin-top: 5px;

}

.form__checkbox {
    .form__input--error {
        margin-bottom: 5px;

    }
}

.form__select + .form__input--error {
    position: relative;
    top: -20px;
}


.form__switch {
    position: relative;
    display: inline-block;
    white-space: nowrap;


    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;
    }

    label {
        padding-right: 60px;;
        line-height: 30px;
        display: inline-block;
        font-size: 20px;
        position: relative;
    }

    /* The slider */
    label:before {
        content: "";
        height: 30px;
        width: 50px;
        border-radius: 16px;
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-15px);
        right: 0;
        right: 0;
        bottom: 0;
        background-color: $bg-grey;
        -webkit-transition: .4s;
        transition: .4s;
        z-index: 2;
        cursor: pointer;
    }

    label:after {
        content: "";
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        right: 24px;
        bottom: 4px;
        border-radius: 11px;
        background-color: #959595;
        -webkit-transition: .4s;
        transition: .4s;
        z-index: 3;
        cursor: pointer;
    }

    input:checked + label:before {
        background-color: $main;
    }

    input:checked + label:after {
        background: #fff;
    }

    input:checked + label:after {
        right: 4px;
    }

    /* Rounded sliders */

}




.form-s {

    fieldset {
        margin-bottom: 20px;
    }

    @media(min-width: 768px) {

        .form__input,
        .form__select {
            width: 100%;
        }

        fieldset {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            column-gap: 20px;
        }

        .form__input--25 {
            max-width: calc(50% - 15px)
        }
    }


    @media(min-width: 992px) {

        .form__input,
        .form__select {
            flex: 0 0 50%;
            max-width: calc(50% - 10px)
        }

        .form__input--25 {
            max-width: calc(25% - 15px)
        }
    }
}

